import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CInfinitySlider,
  CTable,
  LBanquetContact,
} from '../../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: 'お別れの会',
            sub: <>MEMORIAL</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/plan/ds15us00000009sj/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/plan/ds15us00000009sj/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect02 u_pt50 u_hiddenWrap">
        <LWrap>
          <CInfinitySlider
            data={[
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj03.png',
                  alt: '',
                },
              },
            ]}
          />
          <h2 className="c_headingLv2 u_mb20 u_tac">
            故人のご冥福を祈る厳かなひととき
            <br />
            在りし日の笑顔を想い、語る和やかな会
          </h2>
          <p className="c_sectLead u_mb50">
            ロイヤルパークホテルならではの、格調高い会場と料理、そしてサービスで、落ち着いた時間をお過ごしください。
            <br />
            故人のご生存時の想いを祭壇や空間全体で表現し、ご参会の方々、
            <br className="u_pc" />
            ご遺族の方々がさまざまな思い出を語り合い優しい気持ちに包まれて心からご満足していただけるようおもてなしをさせていただきます。
          </p>
          <CTable
            data={[
              {
                title: '交通',
                content: (
                  <>
                    東京駅から約2km、東京メトロ半蔵門線「水天宮前」駅直結と大変便利です。また、駐車場もございますので会場への移動も便利です。
                  </>
                ),
              },
              {
                title: '会場',
                content: (
                  <>
                    最新設備を有するロイヤルホールは6mの天井高で2,000名様収容可能です。また会場に隣接したお控室をご用意いたします。
                  </>
                ),
              },
              {
                title: 'お食事',
                content: (
                  <>
                    ご会葬いただいた方への心配りと関係者への労をねぎらうために、皆様の心に残る内容にてご用意させていただきます。
                  </>
                ),
              },
              {
                title: '会場',
                content: (
                  <>
                    <p className="u_mb20">
                      ご案内状などの各種印刷物の手配をはじめ、献花やお引出物等、ご葬儀に関するあらゆる準備をお手伝いさせていただきます。
                    </p>
                    <div className="c_3col gap_20">
                      <div className="col">
                        <img
                          src="/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj04.png"
                          className="radius_none"
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <img
                          src="/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj05.png"
                          className="radius_none"
                          alt=""
                        />
                      </div>
                      <div className="col">
                        <img
                          src="/assets/images/banquet/plan/ds15us00000009sj/img_ds15us00000009sj06.png"
                          className="radius_none"
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
